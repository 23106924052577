import React from "react";
import PropTypes from "prop-types";
import { graphql, Link } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";

import "./OperationsProgrammees.scss";
import "./TwoColumnsTemplate.scss";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";

export const OperationsProgrammeesTemplate = ({
  title,
  content,
  contentComponent,
  panelimage,
  button,
  buttonText,
}) => {
  const PageContent = contentComponent || Content;

  return (
    <>
      <h1>{title}</h1>
      <div className="ContentElement__wrapper">
        <div className="ContentElement">
          <PageContent className="Markdown" content={content} />
          {button && (
            <Link className="OperationsProgrammees__Button" to={button}>
              {buttonText}
            </Link>
          )}
        </div>
        {panelimage && (
          <PreviewCompatibleImage
            className="ContentElement ContentElement__image"
            imageInfo={{
              image: panelimage,
              alt: `Side image ${title}`,
            }}
          />
        )}
      </div>
    </>
  );
};

OperationsProgrammeesTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
};

const OperationsProgrammees = ({ data }) => {
  const { markdownRemark: post } = data;
  const {
    frontmatter: { title, panelimage, description, button, buttonText } = {},
    html,
  } = post || {};

  return (
    <Layout>
      <section className="Layout__body">
        <OperationsProgrammeesTemplate
          contentComponent={HTMLContent}
          title={title}
          description={description}
          content={html}
          panelimage={panelimage}
          button={button}
          buttonText={buttonText}
        />
      </section>
    </Layout>
  );
};

OperationsProgrammees.propTypes = {
  data: PropTypes.object.isRequired,
};

export default OperationsProgrammees;

export const OperationsProgrammeesQuery = graphql`
  query OperationsProgrammees($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        description
        panelimage {
          childImageSharp {
            fluid(maxWidth: 960, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        button
        buttonText
      }
    }
  }
`;
